const LOCALSTORAGE_VERSION = "v7";

export const LOCALSTORAGE_BASKET_CURRENT = "basket_" + LOCALSTORAGE_VERSION
export const LOCALSTORAGE_CHECKOUT_CURRENT = "checkout_" + LOCALSTORAGE_VERSION
export const LOCALSTORAGE_T = "t_" + LOCALSTORAGE_VERSION
export const LOCALSTORAGE_PAYMENT = "payment_" + LOCALSTORAGE_VERSION
export const LOCALSTORAGE_MODAL_RESET_PASSWORD = "show_modal_reset_password_" + LOCALSTORAGE_VERSION
export const LOCALSTORAGE_CAMPAIGN_CURRENT = "campaign"
export const LOCALSTORAGE_LEAD = "lead"
export const LOCALSTORAGE_LEADCOMBINATION = "lead_combination"
export const LOCALSTORAGE_RESERVATION = "reservation"
